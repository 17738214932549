/**
 * @name Share
 * @description Class to apply functionality to the Share
 */
class Share {
  constructor() {
    /**
     * @name shareFacebookBtn
     * @type {HTMLElement}
     */
    this.shareFacebookBtn = document.getElementById('share__icon--facebook');

    /**
     * @name shareTwitterBtn
     * @type {HTMLElement}
     */
    this.shareTwitterBtn = document.getElementById('share__icon--twitter');

    /**
     * @name sharePinterestBtn
     * @type {HTMLElement}
     */
    this.sharePinterestBtn = document.getElementById('share__icon--pinterest');

    /**
     * @name shareLinkBtn
     * @type {HTMLElement}
     */
    this.shareLinkBtn = document.getElementById('share__icon--link');

    this.init();
  }

  /**
   * @method init
   * @memberof Share
   */
  init() {
    this.bindEvents();
  }

  /**
   * Binds the Share events
   *
   * @method bindEvents
   * @memberof Share
   */
  bindEvents() {
    if (this.shareFacebookBtn) {
      this.shareFacebookBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let url = this.getURL();
        url = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        this.openWindow(url);
      });
    }

    if (this.shareTwitterBtn) {
      this.shareTwitterBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let url = this.getURL();
        url = `https://twitter.com/intent/tweet?text=${url}`;
        this.openWindow(url);
      });
    }

    if (this.sharePinterestBtn) {
      this.sharePinterestBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let url = this.getURL();
        url = `https://pinterest.com/pin/create/button/?url=${url}`;
        this.openWindow(url);
      });
    }

    if (this.shareLinkBtn) {
      this.shareLinkBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let url = this.getURL();
        this.copyToClipboard(url);
      });
    }
  }

  /**
   * Copy text to clipboard
   *
   * @method copyToClipboard
   * @memberof Share
   */
  copyToClipboard(text) {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  }

  /**
   * Get the URL
   *
   * @method getURL
   * @memberof Share
   */
  getURL() {
    // Clean up the URL
    let url = window.location.href.split('#')[0];
    url = url.split('?')[0];
    url = this.shareID ? `${url}?share=${this.shareID}` : url;
    return url;
  }

  /**
   * Open a window for the Social Sharer
   *
   * @method openWindow
   * @memberof Header
   * @param {String} url
   */
  openWindow(url) {
    const winWidth = 650;
    const winHeight = 450;
    const winLeft = (window.innerWidth - winWidth) / 2;
    const winTop = (window.innerHeight - winHeight) / 2;
    const winOptions = `width=${winWidth},height=${winHeight},top=${winTop},left=${winLeft}`;
    window.open(url, 'Share', winOptions);
  }
}

export default Share;
