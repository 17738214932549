import Pristine from 'pristinejs';
import axios from 'axios';
import formConfig from '../../../../config/form';

/**
 * @name McForms
 * @description Class to apply functionality to the Forms
 */
class McForms {
  constructor() {
    /**
     * @name form
     * @type {HTMLElement}
     */
    this.mcForms = [...document.getElementsByClassName('mc-form')];

    this.init();
  }

  /**
   * @method init
   * @memberof McForms
   */
  init() {
    this.bindEvents();
  }

  /**
   * Binds the submit
   *
   * @method bindEvents
   * @memberof McForms
   */
  bindEvents() {
    this.mcForms.forEach((mcForm) => {
      const errorMsg = mcForm.querySelector('.forms-header__error-msg');
      const pristine = new Pristine(mcForm, formConfig);
      mcForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const valid = pristine.validate();

        if (valid) {
          // Remove the error message
          errorMsg.classList.remove('forms-header__error-msg--show');

          // Show the loading state
          mcForm.classList.add('forms--loading');
          const submit = mcForm.querySelector('.form__submit');
          const submitText = submit.value;
          submit.value = 'Sending';

          // eslint-disable-next-line no-undef
          const formData = new FormData(mcForm);

          // Get the recaptcha token and add to the formData object
          if (grecaptcha) {
            grecaptcha.ready(() => {
              grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, { action: 'submit_form' }).then((token) => {
                formData.append('recaptcha_token', token);
                formData.append('recaptcha_action', 'submit_form');

                axios.post(mcForm.getAttribute('action'), formData)
                  .then((response) => {
                    console.log('response', response);
                    if (response.data.mail === '200') {
                      mcForm.parentNode.parentNode.classList.add('forms--success');
                    } else {
                      errorMsg.classList.add('forms-header__error-msg--show');
                    }
                  })
                  .catch((error) => {
                    console.log('Error!!', error);
                    mcForm.parentNode.parentNode.classList.add('forms--error');
                  })
                  .finally(() => {
                    mcForm.classList.remove('forms--loading');
                    mcForm.parentNode.parentNode.scrollIntoView();
                    submit.value = submitText;
                  });
              });
            });
          }
        }
      });
    });
  }
}

export default McForms;
