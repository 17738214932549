const formConfig = {
  // class of the parent element where the error/success class is added
  classTo: 'form__input-wrap',
  errorClass: 'form__input-wrap--error',
  successClass: 'success',
  // class of the parent element where error text element is appended
  errorTextParent: 'form__input-wrap',
  // type of element to create for the error text
  errorTextTag: 'div',
  // class of the error text element
  errorTextClass: 'form__input-error',
};

export default formConfig;
