/**
 * @name LastLine
 * @description Class to apply a span wrapper to the last line of text dynamically.
 */
class LastLine {
  constructor() {

    this.elements = document.querySelectorAll('.js-last-line');
    this.nameElement = 'last-line__element';
    this.nameRow = 'last-line__row';

    this.init();
  }

  /**
   * @method init
   * @memberof LastLine
   */
  init() {
    this.findLastRows();
    this.bindEvents();
  }

  wrapAll(nodes, wrapper, elem) {
    const parent = nodes[0].parentNode;
    const { previousSibling } = nodes[0];
    for (let i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && (i += 1)) {
      wrapper.appendChild(nodes[i]);
    }
    if (previousSibling) {
      parent.insertBefore(wrapper, previousSibling.nextSibling);
    } else {
      elem.appendChild(wrapper);
    }
    return wrapper;
  }

  findLastRow(elem) {
    const content = elem.innerText.trim();
    const contentArr = content.split(' ');
    let contentFormatted = '';
    contentArr.forEach((item) => {
      contentFormatted += `<span>${item} </span>`;
    });
    const element = elem;
    element.innerHTML = contentFormatted;

    const childrenSpan = Array.from(elem.getElementsByTagName('span'));
    let top = 0;
    childrenSpan.forEach((item) => {
      const thisTop = item.offsetTop;
      if (thisTop === top) {
        item.classList.add(this.nameElement);
      } else {
        childrenSpan.forEach((el) => {
          el.classList.remove(this.nameElement);
        });
        top = thisTop;
        item.classList.add(this.nameElement);
      }
    });

    const wrapElements = element.querySelectorAll(`.${this.nameElement}`);
    const wrapper = document.createElement('span');
    wrapper.classList.add(this.nameRow);
    this.wrapAll(wrapElements, wrapper, elem);
  }

  findLastRows() {
    this.elements.forEach((elem) => {
      this.findLastRow(elem);
    });
  }

  bindEvents() {
    window.addEventListener('resize', () => {
      this.findLastRows();
    });
  }
}

export default LastLine;
