/* global IntersectionObserver */

/**
 * @name ScrollAnimate
 * @description Class to apply Intersection Observer rules to the specified elements.
 *              When the elements are scrolled into view, the class is applied, which
 *              triggers the CSS transition (fade and slide up).
 */
class ScrollAnimate {
  constructor() {
    /**
     * @name scrollAnimItems
     * @type {Array<[HTMLElement]>}
     */
    this.scrollAnimItems = [...document.getElementsByClassName('scroll-anim')];

    // Only run if correct classes are found in page
    if (this.scrollAnimItems.length > 0) {
      this.init();
    }
  }

  /**
   * @method init
   * @memberof ScrollAnimate
   */
  init() {
    // Only apply if IntersectionObserver is supported
    if ('IntersectionObserver' in window) {
      const options = {
        rootMargin: '0px', // margin around root.
        threshold: 0.1, // visible amount of item shown in relation to root
      };
      const observer = new IntersectionObserver(this.onChange, options);
      // start observing your elements
      this.scrollAnimItems.forEach(item => observer.observe(item));
    } else {
      // Otherwise, force the animation so the items show
      this.scrollAnimItems.forEach(item => item.classList.add('animate'));
    }
  }

  /**
   * @method onChange
   * @memberof ScrollAnimate
   * @param {Array} changes [array to which we apply the intersection observer rules]
   */
  // eslint-disable-next-line class-methods-use-this
  onChange(changes) {
    changes.forEach((change) => {
      if (change.intersectionRatio > 0) {
        change.target.classList.add('animate');
      }
    });
  }
}

export default ScrollAnimate;
