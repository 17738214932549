import axios from 'axios';
import template from './page-recipes-recipe/page-recipes-recipe.jstwig';

/**
 * @name PageRecipes
 * @description Class to apply functionality to the PageRecipes
 */
class PageRecipes {
  constructor() {
    this.recipeFilterMeal = document.getElementById('recipe-filter-meal');
    this.recipeFilterIngredient = document.getElementById('recipe-filter-ingredient');
    this.clearFilterBtn = document.getElementById('page-recipes-intro__clear-filter');
    this.recipesContainerEl = document.getElementById('page-recipes-results__recipes');
    this.recipesPageEl = document.getElementById('page-recipes');
    this.resultsTitle = document.getElementById('page-recipes-results__title');
    this.recipesScrollDetect = document.getElementById('page-recipes-results-scroll-detect');

    this.meal = '';
    this.ingredient = '';

    this.page = 1;
    this.forceLatest = false;

    this.loading = false;

    this.init();
  }

  /**
   * @method init
   * @memberof PageRecipes
   */
  init() {
    this.bindEvents();
    this.loadOnScroll();
    this.checkQueryParams();
    this.loadRecipes();
  }

  /**
   * Binds the submit
   *
   * @method bindEvents
   * @memberof PageRecipes
   */
  bindEvents() {
    this.recipeFilterMeal.addEventListener('change', this.filterByMeal.bind(this));
    this.recipeFilterIngredient.addEventListener('change', this.filterByIngredient.bind(this));
    this.clearFilterBtn.addEventListener('click', this.clearFilters.bind(this));
  }

  /**
   * Sets up an IntersectionObserver to detect when the user has scrolled to bottom of page
   *
   * @method bindEvents
   * @memberof PageRecipes
   */
  loadOnScroll() {
    const options = {
      rootMargin: '100px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver(this.onScrollCallback.bind(this), options);
    this.observer.observe(this.recipesScrollDetect);
  }

  onScrollCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.loading) {
        // We only want this to fire once for each load
        this.observer.unobserve(this.recipesScrollDetect);
        this.loadRecipes(true);
      }
    });
  }

  checkQueryParams() {
    // Check for query params
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('meal')) {
      const meal = queryParams.get('meal');
      this.meal = meal;
      setTimeout(() => {
        this.recipeFilterMeal.customSelect.value = meal;
      });
    }
    if (queryParams.has('ingredient')) {
      const ingredient = queryParams.get('ingredient');
      this.ingredient = ingredient;
      setTimeout(() => {
        this.recipeFilterIngredient.customSelect.value = ingredient;
      });
    }
  }

  filterByMeal(e) {
    this.meal = e.target.value;
    this.filterChange();
  }

  filterByIngredient(e) {
    this.ingredient = e.target.value;
    this.filterChange();
  }

  filterChange() {
    // Construct URLSearchParams object instance from current URL querystring.
    const queryParams = new URLSearchParams(window.location.search);

    if (!this.meal && !this.ingredient) {
      this.clearFilters();
      queryParams.set('meal', this.meal);
      queryParams.set('ingredient', this.ingredient);
    } else {
      this.forceLatest = false;
      this.page = 1;
      this.loadRecipes();
      if (this.meal) queryParams.set('meal', this.meal);
      if (this.ingredient) queryParams.set('ingredient', this.ingredient);
    }

    // Update the URL
    history.replaceState(null, null, `?${queryParams.toString()}`);
  }

  clearFilters() {
    this.forceLatest = false;
    this.meal = '';
    this.ingredient = '';
    this.recipeFilterMeal.customSelect.value = '';
    this.recipeFilterIngredient.customSelect.value = '';
    this.resetClasses();
    this.resetRecipes();
  }

  resetClasses() {
    this.recipesPageEl.classList.remove('page-recipes--loading', 'page-recipes--filtered', 'page-recipes--not-found');
  }

  resetRecipes() {
    this.page = 1;
    // this.resultsTitle.textContent = 'Latest Recipes';
    this.loadRecipes();
  }

  async loadRecipes(loadMore = false) {
    this.loading = true;
    // If not loading more, remove the previous recipes
    if (!loadMore) {
      this.recipesContainerEl.innerHTML = '';
    }

    // Set the loading state
    this.recipesPageEl.classList.add('page-recipes--loading');
    this.recipesPageEl.classList.add('page-recipes--loading-more');

    const mealQuery = this.meal ? `&meal=${this.meal}` : '';
    const ingredientQuery = this.ingredient ? `&ingredient=${this.ingredient}` : '';
    const filtersQuery = this.forceLatest ? '' : `${mealQuery}${ingredientQuery}`;

    try {
      const response = await axios.get(`/wp-json/biffs/v1/recipes?${filtersQuery}&page=${this.page}`);
      const { data } = response;
      this.page = data.current_page;
      const { recipes } = data;

      // If recipes are returned render them, otherwise show the no recipes found state,
      // but only if this is for the first page of results
      if (recipes.length >= 1) {
        this.renderRecipes(recipes);

        const isFiltered = this.meal || this.ingredient;

        // Update the title
        if (isFiltered && !this.forceLatest) {
          this.resultsTitle.textContent = 'Results';
          this.recipesPageEl.classList.add('page-recipes--filtered');
          this.recipesPageEl.classList.remove('page-recipes--not-found');
        } else {
          this.resultsTitle.textContent = 'Latest Recipes';
          this.recipesPageEl.classList.remove('page-recipes--filtered');
        }
      } else if (this.page === 1) {
        // Recipes not found
        this.recipesPageEl.classList.add('page-recipes--not-found');
        this.recipesPageEl.classList.remove('page-recipes--filtered', 'page-recipes--loading');

        // Reset back to show the Latest Recipes
        this.forceLatest = true;
        this.resetRecipes();
      }

      // If this is the last page, disable the observer
      if (this.page >= data.total_pages) {
        this.observer.unobserve(this.recipesScrollDetect);
      } else {
        this.page = this.page + 1;
      }
    } catch (error) {
      // Something went wrong, reset the Filters and Recipes back to Latest
      this.clearFilters();
    }

    // Remove the loading state
    this.recipesPageEl.classList.remove('page-recipes--loading');
    this.recipesPageEl.classList.remove('page-recipes--loading-more');
    this.loading = false;
  }

  renderRecipes(recipes) {
    recipes.forEach((recipe) => {
      const recipeTemplate = template({ recipe });
      this.recipesContainerEl.insertAdjacentHTML('beforeend', recipeTemplate);
    });

    // Re-enable the scroll observer
    this.observer.observe(this.recipesScrollDetect);
  }
}

export default PageRecipes;
