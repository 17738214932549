/**
 * @name CookieNotification
 * @description Class to apply functionality to the CookieNotification
 */
class CookieNotification {
  constructor() {
    /**
     * @name CookieNotification
     * @type {HTMLElement}
     */
    this.cookieNotification = document.getElementById('cookie-notification');

    /**
     * @name acceptBtn
     * @type {HTMLElement}
     */
    this.acceptBtn = document.getElementById('cookie-notification__btn-primary');

    /**
     * @name cancelBtn
     * @type {HTMLElement}
     */
    this.cancelBtn = document.getElementById('cookie-notification__btn-secondary');

    this.cookieStorage = {
      getItem: (item) => {
        const cookies = document.cookie
          .split(';')
          .map(cookie => cookie.split('='))
          .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
      },
      setItem: (item, value) => {
        document.cookie = `${item}=${value}; path=/; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toGMTString()};`;
      },
    };

    this.consentPropertyName = 'biffs_consent';
    this.shouldShowPopup = () => !this.cookieStorage.getItem(this.consentPropertyName);
    this.saveToStorage = () => this.cookieStorage.setItem(this.consentPropertyName, true);

    this.init();
  }

  /**
   * @method init
   * @memberof CookieNotification
   */
  init() {
    this.bindEvents();

    if (this.shouldShowPopup(this.cookieStorage)) {
      setTimeout(() => {
        this.cookieNotification.classList.remove('cookie-notification--hidden');
      }, 2000);
    }
  }

  /**
   * Binds the CookieNotification events
   *
   * @method bindEvents
   * @memberof CookieNotification
   */
  bindEvents() {
    this.acceptBtn.addEventListener('click', () => { this.accept(); });
    this.cancelBtn.addEventListener('click', () => { this.cancel(); });
  }

  /**
   * Save the cookie and hide the message
   *
   * @method accept
   * @memberof CookieNotification
   */
  accept() {
    this.saveToStorage(this.storageType);
    this.cookieNotification.classList.add('cookie-notification--hidden');
  }

  /**
   * Hide the message
   *
   * @method cancel
   * @memberof CookieNotification
   */
  cancel() {
    this.cookieNotification.classList.add('cookie-notification--hidden');
  }
}

export default CookieNotification;
