import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import turfDistance from '@turf/distance';
import axios from 'axios';

/**
 * @name GetBiffs
 * @description Class to apply functionality to the GetBiffs page
 */
class GetBiffs {
  constructor() {
    mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

    /**
     * @name form
     * @type {HTMLElement}
     */
    this.mapEl = document.getElementById('get-biffs__map');

    this.searchInput = document.getElementById('get-biffs__search');

    this.mapResultsEl = document.getElementById('get-biffs__map-results');

    this.header = document.getElementById('header');

    this.type = document.getElementById('get-biffs__type').value;

    this.maxResults = 10;

    this.init();
  }

  /**
   * @method init
   * @memberof GetBiffs
   */
  init() {
    this.loadData();
  }

  /**
   * Assign a unique id to each store. You'll use this `id`
   * later to associate each point on the map with a listing
   */
  async loadData() {
    try {
      const response = await axios.get(`/wp-json/biffs/v1/dealers?type=${this.type}`);
      this.stores = response.data;
      this.setupMap();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Binds the submit
   *
   * @method
   * @memberof GetBiffs
   */
  setupMap() {
    const self = this;
    self.map = new mapboxgl.Map({
      container: self.mapEl,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-0.12871802, 51.513394],
      zoom: 13,
      maxZoom: 14,
      scrollZoom: false,
    });

    // Disable single finger scrolling on the map for mobile devices
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      self.map.dragPan.disable();
      self.map.scrollZoom.disable();
      self.map.touchPitch.disable();
      self.map.on('touchstart', (e) => {
        const oe = e.originalEvent;
        if (oe && 'touches' in oe) {
          if (oe.touches.length > 1) {
            oe.stopImmediatePropagation();
            self.map.dragPan.enable();
          } else {
            self.map.dragPan.disable();
          }
        }
      });
    }

    // Add zoom and rotation controls to the map.
    self.map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    // Initialize the geolocate control.
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

    // Add the control to the map.
    self.map.addControl(geolocate, 'top-left');

    // Set an event listener that fires
    geolocate.on('geolocate', (ev) => {
      const { longitude, latitude } = ev.coords;
      const geometry = {
        type: 'Point',
        coordinates: [
          longitude,
          latitude,
        ],
      };
      self.selectLocation(geometry);
    });

    /**
       * Wait until the map loads to make changes to the map.
       */
    self.map.on('load', () => {
      geolocate.trigger();

      /**
       * This is where your '.addLayer()' used to be, instead
       * add only the source without styling a layer
       */
      self.map.addSource('places', {
        type: 'geojson',
        data: this.stores,
      });

      /**
         * Create a new MapboxGeocoder instance.
         */
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        marker: true,
        placeholder: 'Pop in your postcode to get started...',
      });

      // Assign the geocoder to the input
      this.searchInput.appendChild(geocoder.onAdd(self.map));

      /**
         * Add all the things to the page:
         * - The location listings on the side of the page
         * - The search box (MapboxGeocoder) onto the map
         * - The markers onto the map
         */
      self.buildLocationList(this.stores.features);
      self.addMarkers();

      /**
         * Listen for when a geocoder result is returned. When one is returned:
         * - Calculate distances
         * - Sort stores by distance
         * - Rebuild the listings
         * - Adjust the map camera
         * - Open a popup for the closest store
         * - Highlight the listing for the closest store.
         */
      geocoder.on('result', (ev) => {
        const y = self.mapEl.getBoundingClientRect().top
          + window.pageYOffset - this.header.clientHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });
        self.selectLocation(ev.result.geometry);
      });
    });
  }

  selectLocation(geometry) {
    const self = this;

    /* Get the coordinate of the search result */
    const searchResult = geometry;

    /**
     * Calculate distances:
     * For each store, use turf.disance to calculate the distance
     * in miles between the searchResult and the store. Assign the
     * calculated value to a property called `distance`.
     */
    const options = { units: 'miles' };
    self.stores.features.forEach((store) => {
      Object.defineProperty(store.properties, 'distance', {
        value: turfDistance(searchResult, store.geometry, options),
        writable: true,
        enumerable: true,
        configurable: true,
      });
    });

    /**
     * Sort stores by distance from closest to the `searchResult`
     * to furthest.
     */
    self.stores.features.sort((a, b) => {
      if (a.properties.distance > b.properties.distance) {
        return 1;
      }
      if (a.properties.distance < b.properties.distance) {
        return -1;
      }
      return 0; // a must be equal to b
    });

    /**
     * Rebuild the listings:
     * Remove the existing listings and build the location
     * list again using the newly sorted stores.
     */
    const listings = document.getElementById('get-biffs__map-results');
    while (listings.firstChild) {
      listings.removeChild(listings.firstChild);
    }

    // const filteredFeatures = self.stores.features.slice(0, this.maxResults);
    const filteredFeatures = self.stores.features;
    self.buildLocationList(filteredFeatures);

    /* Open a popup for the closest store. */
    self.createPopUp(filteredFeatures[0]);

    /** Highlight the listing for the closest store. */
    const activeListing = document.getElementById(`get-biffs__map-result--${filteredFeatures[0].properties.id}`);
    activeListing.classList.add('get-biffs__map-result--active');

    // Scroll to the active result
    self.mapResultsEl.scrollTo({ top: activeListing.offsetTop, behavior: 'smooth' });

    /**
     * Adjust the map camera:
     * Get a bbox that contains both the geocoder result and
     * the closest store. Fit the bounds to that bbox.
     */
    const bbox = self.getBbox(filteredFeatures, 0, searchResult);
    self.map.fitBounds(bbox, {
      padding: 100,
    });
  }

  /**
   * Using the coordinates (lng, lat) for
   * (1) the search result and
   * (2) the closest store
   * construct a bbox that will contain both points
   */
  getBbox(filteredFeatures, storeIdentifier, searchResult) {
    const lats = [
      filteredFeatures[storeIdentifier].geometry.coordinates[1],
      searchResult.coordinates[1],
    ];
    const lons = [
      filteredFeatures[storeIdentifier].geometry.coordinates[0],
      searchResult.coordinates[0],
    ];
    const sortedLons = lons.sort((a, b) => {
      if (a > b) { return 1; }
      if (a.distance < b.distance) { return -1; }
      return 0;
    });
    const sortedLats = lats.sort((a, b) => {
      if (a > b) { return 1; }
      if (a.distance < b.distance) { return -1; }
      return 0;
    });
    return [
      [sortedLons[0], sortedLats[0]],
      [sortedLons[1], sortedLats[1]],
    ];
  }

  /**
   * Add a marker to the map for every store listing.
   */
  addMarkers() {
    const self = this;
    /* For each feature in the GeoJSON object above: */
    self.stores.features.forEach((marker) => {
      /* Create a div element for the marker. */
      const el = document.createElement('div');
      /* Assign a unique `id` to the marker. */
      el.id = `get-biffs__marker-${marker.properties.id}`;
      /* Assign the `marker` class to each marker for styling. */
      el.className = `get-biffs__marker get-biffs__marker--${marker.properties.type.replace(/\s+/g, '').toLowerCase()}`;

      /**
       * Create a marker using the div element
       * defined above and add it to the map.
       */
      new mapboxgl.Marker(el, { offset: [0, -23] })
        .setLngLat(marker.geometry.coordinates)
        .addTo(self.map);

      /**
       * Listen to the element and when it is clicked, do three things:
       * 1. Fly to the point
       * 2. Close all other popups and display popup for clicked store
       * 3. Highlight listing in sidebar (and remove highlight for all other listings)
       */
      el.addEventListener('click', (e) => {
        self.flyToStore(marker);
        self.createPopUp(marker);
        const activeItem = [...document.getElementsByClassName('get-biffs__map-result--active')][0];
        e.stopPropagation();
        if (activeItem) {
          activeItem.classList.remove('get-biffs__map-result--active');
        }
        const listing = document.getElementById(`get-biffs__map-result--${marker.properties.id}`);
        listing.classList.add('get-biffs__map-result--active');

        // Scroll to the active result
        self.mapResultsEl.scrollTo({ top: listing.offsetTop, behavior: 'smooth' });
      });
    });
  }

  /**
   * Add a listing for each store to the sidebar.
   */
  buildLocationList(features) {
    const self = this;

    features.forEach((store) => {
      /**
       * Create a shortcut for `store.properties`,
       * which will be used several times below.
       */
      const { properties } = store;
      const {
        id,
        type,
        biffs_owned: biffsOwned,
        title, address,
        towncity,
        postcode,
        url,
        deliveroo_url: deliverooUrl,
        direct_url: directUrl,
      } = properties;

      let {
        address2,
        distance,
      } = properties;

      /* Add a new listing section to the sidebar. */
      const listings = document.getElementById('get-biffs__map-results');
      const listing = listings.appendChild(document.createElement('div'));
      /* Assign a unique `id` to the listing. */
      listing.id = `get-biffs__map-result--${id}`;
      /* Assign the `item` class to each listing for styling. */
      listing.className = 'get-biffs__map-result';

      /* Add the link to the individual listing created above. */
      const btn = listing.appendChild(document.createElement('button'));
      btn.className = 'get-biffs__map-result-btn';
      btn.id = `get-biffs__map-result-btn--${id}`;
      btn.type = 'button';

      if (distance) {
        const roundedDistance = Math.round(distance * 100) / 100;
        distance = `<p class="get-biffs__map-result-distance">${roundedDistance} miles away</p>`;
      }

      address2 = address2 ? `, ${address2}` : '';

      btn.innerHTML = `<img class="get-biffs__map-result-img" src="/wp-content/themes/biffs/assets/img/map/map-result-${type}.svg" alt="" />
      <div class="get-biffs__map-result-btn-text-wrap">
        <h3 class="get-biffs__map-result-title">${title}</h3>
        <p class="get-biffs__map-result-address">${address}${address2}, ${towncity} ${postcode}</p> ${distance}
      </div>`;

      // Add the links
      const urlLink = url ? `<a href="${url}" class="get-biffs__map-result-link page__body-cta--border" target="_blank">${biffsOwned === 'yes' ? 'See more' : 'Website'}</a>` : '';
      const deliverooLink = deliverooUrl ? `<a href="${deliverooUrl}" class="get-biffs__map-result-link page__body-cta--border" target="_blank">Deliveroo</a>` : '';
      const directLink = directUrl ? `<a href="${directUrl}" class="get-biffs__map-result-link page__body-cta--border" target="_blank">Order Direct</a>` : '';

      const combinedLinks = `${deliverooLink}${urlLink}${directLink}`;

      // Add the link to the individual listing created above.
      listing.insertAdjacentHTML('beforeend', combinedLinks);

      /**
       * Listen to the element and when it is clicked, do four things:
       * 1. Update the `currentFeature` to the store associated with the clicked link
       * 2. Fly to the point
       * 3. Close all other popups and display popup for clicked store
       * 4. Highlight listing in sidebar (and remove highlight for all other listings)
       */
      btn.addEventListener('click', (e) => {
        const el = e.target;
        e.preventDefault();
        for (let i = 0; i < features.length; i += 1) {
          if (el.id === `get-biffs__map-result-btn--${features[i].properties.id}`) {
            const clickedListing = features[i];
            self.flyToStore(clickedListing);
            self.createPopUp(clickedListing);
          }
        }
        const activeItem = document.getElementsByClassName('get-biffs__map-result--active');
        if (activeItem[0]) {
          activeItem[0].classList.remove('get-biffs__map-result--active');
        }
        el.parentNode.classList.add('get-biffs__map-result--active');
      });
    });
  }

  /**
   * Use Mapbox GL JS's `flyTo` to move the camera smoothly
   * a given center point.
   */
  flyToStore(currentFeature) {
    this.map.flyTo({
      center: currentFeature.geometry.coordinates,
      zoom: 14,
    });
  }

  /**
   * Create a Mapbox GL JS `Popup`.
   */
  createPopUp(currentFeature) {
    const self = this;
    const popUps = document.getElementsByClassName('mapboxgl-popup');
    if (popUps[0]) popUps[0].remove();

    new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(currentFeature.geometry.coordinates)
      .setHTML(`<h3>${currentFeature.properties.title}</h3>`)
      .addTo(self.map);
  }
}

export default GetBiffs;
