import customSelect from 'custom-select';
import Header from './components/header/Header';
import ScrollAnimate from './utils/scrollAnimate';
import LastLine from './utils/lastLine';
import McForms from './components/flexible-content/forms/forms';
import GetBiffs from './components/flexible-content/get-biffs/get-biffs';
import CookieMessage from './components/cookie-notification/cookie-notification';
import Share from './components/share/share';
import PageRecipes from './pages/page-recipes/page-recipes';

new CookieMessage();

new Share();

// Add Scroll Animation
new ScrollAnimate();
new LastLine();

// Add Component and Page Scripts
new Header();

if (document.getElementsByClassName('mc-form').length >= 1) {
  new McForms();
}

if (document.getElementById('get-biffs__map')) {
  new GetBiffs();
}

if (document.getElementsByClassName('page-recipes').length >= 1) {
  new PageRecipes();
}

customSelect('select', {
  containerClass: 'custom-select__container',
  openerClass: 'custom-select__opener',
  panelClass: 'custom-select__panel',
  optionClass: 'custom-select__option',
  optgroupClass: 'custom-select__optgroup',
  isSelectedClass: 'custom-select__option--is-selected',
  hasFocusClass: 'custom-select__option--has-focus',
  isDisabledClass: 'custom-select__container--is-disabled',
  isOpenClass: 'custom-select__container--is-open',
});
